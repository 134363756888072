


.zoom{
       
       transition: all 0.2s ease;
       cursor: pointer;
    z-index: 100;
    margin: 10px;
    border-radius: 10px;
box-shadow: 0 0 21px 0px rgb(201 210 210 / 40%);
z-index: 0;
    
       position: inherit;
       overflow: overlay;



}

.card-img-top{
       border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        /* height: 214px;
        width: 100%; */

}

.zoom:hover {
     
       box-shadow: 5px 6px 6px 2px #e9ecef;
       transform: scale(1.1);
       cursor: pointer;
       z-index: 100;
       
}

.itemsContainer:hover .play {
       display: block;

}

.play {
       position: absolute;
       display: none;
       top: 24%;
       width: 40px;
       margin: 0 auto;
       left: 0px;
       right: 15px;
       z-index: 100
}
.play img{
       width: 70px;
}
.iq-card-post-toolbar{


   
border-radius: 16px;
       /* background-color: #e5e3ed; */
       color: white;
       padding: 1px 0px 3px 3px;
       width: 29px;
       background-color: #9267ff;
    }
.ri-more-fill:before {
       content: "\ee1c";
       color: white;
}
    /* .drops{
      position: absolute !important;
    will-change: transform !important;
    top: -18px !important;
    left: -84px !important;
    transform: translate3d(0px, 23px, 0px);
    } */




    @media only screen and (min-width: 1441px) and (max-device-width: 2560px) {
       .play img {
                     width: 130px;
              }
                            .play {
                                   position: absolute;
                                   display: none;
                                   top: 30%;
                                   width: 40px;
                                   margin: 0 auto;
                                   left: 0px;
                                   right: 35px;
                                   z-index: 100;
                            }
                       
    }

    @media only screen and (min-width: 1025px) and (max-device-width: 1440px) {
           .play img {
                  width: 70px;
           }

           .play {
                  position: absolute;
                  display: none;
                  top: 28%;
                  width: 40px;
                  margin: 0 auto;
                  left: 0px;
                  right: 17px;
                  z-index: 100;
           }
    }
    


/* start css for video screen  */
    /* .video-container {
           position: relative;
           overflow: hidden;
           padding-top: 56.25%;
    } */

    .card-video {
           
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
    }
.overflocss{
       overflow-x: clip;
}
/* end css for video screen */

  @media only screen and (min-width: 2340px) and (max-device-width: 2560px) {
        .overflocss{
              min-height: 180px !important;
                     padding-top: 124px !important;
        }
 }

 @media only screen and (min-width: 2220px) and (max-device-width: 2330px) {
        .overflocss {
               min-height: 135px !important;
               padding-top: 105px !important;
        }
 }
 @media only screen and (min-width: 1910px) and (max-device-width: 2245px) {
        .overflocss {
              min-height: 173px !important;
                     padding-top: 94px !important;
        }
 }
 @media only screen and (min-width: 1024px) and (max-device-width: 1905px) {
        .overflocss {
             
              min-height: 70px !important;
                     padding-top: 58px !important;
        }
 }
 @media only screen and (min-width: 990px) and (max-device-width: 1023px) {
        .overflocss {
               min-height: 164px !important;
               padding-top: 60px !important;
        }
 }
  @media only screen and (min-width: 730px) and (max-device-width: 989px) {
         .overflocss {
                min-height: 180px !important;
                padding-top: 49px !important;
         }
  }
   @media only screen and (min-width: 570px) and (max-device-width: 729px) {
          .overflocss {
                 min-height: 172px !important;
                 padding-top: 44px !important;
          }
   } 


 /* .video-element {
        width: 100%;
        height: auto;
        
 } */