




/* side bar css */
/* hr {
       border-color: #5d3b3b5c;
} */

/* side bar css  end*/





.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
       /* color: var(--iq-primary); */
       /* background: var(--iq-light-primary); */
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
       /* color: #fff; */
       /* background-color: #007bff; */
}

.nav-pills li a.active {
       color: var(--iq-white) !important;
       background-color: #9267ff !important;
}

.nav-pills .nav-link {
       border-radius: 25px;
}

.twobtn {
       justify-content: space-between;
       border: 1px solid;
       border-radius: 30px;
       /* background:rgb(193 205 213 / 19%); */
       background-color: #8e9c962b;
}

.btnwidth {
       width: 120px;
}

.icon_bgcss button {
       box-shadow: 0 0 10px 0 rgb(222 226 230 / 75%);
}

.audio_script {
       border-radius: 20px;
}

.btncss {
       text-align: center;
}

.audio_cardcss {
       box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%);
       border-radius: 10px;
       cursor: pointer;
}

.uploadicon {
       font-size: 30px;
}

.micro {
       font-size: 40px;
}

.img_card {
       box-shadow: 0 0 0px 0 rgb(217 198 198 / 38%);
       border-radius: 10px;
       background: #8e9c962b;
       cursor: pointer;
}

.img_card img {
       /* width: 230px;
       justify-content: center; */
       width: 100%;
              height: auto;
}

.circle {
       border-radius: 50%;
       height: 20px;
       width: 20px;
       border: 1px solid black;
       margin: 8px 14px;
}
.circle span{
       position: absolute;
       margin: 18px 0px 0px 2px;
              /* top: 62px; */
              /* left: 224px; */
              font-size: smaller;
       }


.circle1 {
              /* border-radius: 50%;
              height: 50px;
              width: 50px;
              border: 1px solid lightgray;
              margin: 11px 0px 0px -25px;
              box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%);
              background: white; */
              border-radius: 50%;
                     height: 50px;
                     width: 50px;
                     border: 1px solid lightgray;
                     margin: 11px 0px 0px -110px;
                     box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%);
                     background: white;
}

.squre {
       border-radius: 25%;
       height: 17px;
       width: 20px;
       border: 2px solid black;
       margin: 8px 14px;
}
.squre span{
       position: absolute;
       margin: 15px 0px 0px -4px;
              /* top: 62px; */
              /* left: 224px; */
              font-size: smaller;
       
}

/* css for choose a presenter and generate AI presenter start*/
.nav-tabs {
       border-bottom: 0px solid var(--iq-light-primary);
       margin-bottom: 15px;
}

.la-user:before {
       content: "\f007";
       font-size: 18px;
}

.la-atom:before {
       content: "\f5d2";
       font-size: 16px;
}

.badge_css {
       background: #8667d2;
}

.choosepresnt img {
       /* padding: 0.25rem;
       background-color: #fff;
       border: 1px solid #dee2e6;
       border-radius: 50% !important; */
       /* 
       height: 105px;
       width: 105px; */
       border-radius: 50%;
       /* box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%); */
       box-shadow: 0px 0px 9px 4px rgb(217 198 198 / 38%);
       border-top: 2px solid #9295ef;
       border-left: 2px solid #9295ef;
       border-right: 2px solid #9042c3;
       border-bottom: 2px solid #9042c3;
       cursor: pointer;
}

/* css for right side card volume, watch,icon and text mice etc  */
.choosepresnt p {
       padding: 0.25rem;
       background-color: #fff;
       border: 1px solid #dee2e6;
       border-radius: 50% !important;
       max-width: 21%;
       height: auto;
}

.right_card {
       padding-bottom: 150px;
}

.script_css {
       border: 1px solid lightgray;
       border-radius: 12px;
       padding: 10px;
}
/* css for right side card volume, watch,icon and text mice etc end */

/* css for plus Add text inside circle */
.add_plus {
       /* border-radius: 50%;
       height: 100px;
       width: 100px;
       border: 1px solid lightgray;
       margin: 34px 0px 0px 0px;
       box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%); */
       height: 100px;
       width: 105px;
       border-radius: 50%;
       font-size: 20px;
       color: #fff;
       cursor: pointer;

       text-align: center;
       background: #1e3d73;
       padding: 28px 3px 0px 0px;
       margin-top: 8px;
       margin-right: 5px;
       box-shadow: 0px 0px 0px 4px rgb(179 138 138 / 38%);
}

/* css for plus Add text inside circle end*/

/* css for images scrollbar */
.scrollbehve1 {
       float: left;
       height: 207px;
       overflow-y: scroll;
       width: -webkit-fill-available;
       margin-bottom: 20px;
}
.scrollbehve1::-webkit-scrollbar {
       width: 2px;
       /* background-color: #F5F5F5; */
}
/* css for images scrollbar end*/

.generate_ai {
       box-shadow: 0 0 10px 0 rgb(217 198 198 / 38%);
       border-radius: 5px;
}

.img_boreder_circle {
       border-radius: 50%;
       height: 20px;
       width: 20px;
       border: 1px solid #00000021;
       margin: 4px;
       position: absolute;
       left: 192px;
       top: 18px;
       font-size: 10px;
       padding: 1px 2px;
       background: #d278c5;
       color: white;
       cursor: pointer;
}

.img_boreder {
       border-radius: 50%;
       height: 20px;
       width: 20px;
       border: 1px solid #00000021;
       margin: 4px;
       position: absolute;
       left: 302px;
       top: 18px;
       font-size: 10px;
       padding: 1px 2px;
       background: #d278c5;
       color: white;
       cursor: pointer;
}

@media only screen and (min-width: 576px) and (max-device-width: 768px) {
       .add_plus {
              height: 93px;
              width: 96px;

              text-align: center;

              padding: 23px 3px 0px 0px;
              margin-top: 8px;
              margin-right: 5px;
       }
}

@media only screen and (min-width: 576px) and (max-device-width: 767px) {
       .img_boreder {
              left: 271px !important;
       }
}

@media only screen and (min-width: 769px) and (max-device-width: 2560px) {
       .add_plus {
              height: 91px;
              width: 95px;
       }
}

@media only screen and (min-width: 280px) and (max-device-width: 575px) {
       .choosepresnt img {
              max-width: none;
       }

       .add_plus {
              height: 93px;
              width: 96px;
       }
}

/* css for choose a presenter and generate AI presenter end*/


/* css for right-card dropdowns  */

   .dropdown-select{
       border: 1px solid #212529;
              border-radius: 5px;
              padding: 6px;
              text-align: start;
              background: transparent;
   }

   .drop_menu{
       border: 1px solid black;
       cursor: pointer;

      
   }
   .drop_menu li:hover{
    
       background-color:#9267ff;
       border: 1px black;
       border-radius: 3px;
       padding: 5px;
       color: white;
   }
/* css for right-card dropdowns end*/


/* css for side-bar mark-icon */
.la-redo:before {
       content: "\f01e";
       /* border: 1px solid black; */
       border-radius: 16px;
       background-color: #9267ff;
       color:white;
       padding: 8px;
       cursor: pointer;
}

/* css for side-bar mark-icon end*/

.generateclick{
       
       height: 45px;
              width: 250px;
              line-height: 45px;
              background: transparent;
              border: 1px solid var(--iq-border-light);
              font-size: 14px;
              color: var(--iq-secondary);
              border-radius: 5px;
              border-bottom: none;
              border-top: none;
}


@media only screen and (min-width: 280px) and (max-device-width: 560px) {
.generate_ai {
              height: 68px;
              width: 255px !important;
              background: transparent;
              border: 1px solid var(--iq-border-light);
              font-size: 14px;
              color: var(--iq-secondary);
              border-radius: 5px;
              border-bottom: none;
              border-top: none;
       }
              .generateclick {
                     height: 70px;
                            width: 110px;
                            line-height: 45px;
                            background: transparent;
                            border: 1px solid var(--iq-border-light);
                            font-size: 14px;
                            color: var(--iq-secondary);
                            border-radius: 5px;
                            border-bottom: none;
                            border-top: none;
              }
}


@media only screen and (min-width: 290px) and (max-device-width: 380px) {
       .circle1 {
                     border-radius: 60%;
                     height: 40px;
                     width: 40px;
                     border: 1px solid lightgray;
                     margin: 8px 0px 0px -87px;
                    
                
              }
       
              .circle {
                     border-radius: 50%;
                     height: 15px;
                     width: 15px;
              
                     margin: 8px 12px;
              }
       
              .circle span {
                    
                     margin: 10px 0px 0px 0px;
                     /* top: 62px; */
                     /* left: 224px; */
                     font-size: smaller;
              }
       
              .squre {
                     border-radius: 25%;
                     height: 15px;
                     width: 15px;
                
                     margin: 7px 12px;
              }
       
              .squre span {
                   
                     margin: 9px 0px 0px -8px;
                     /* top: 62px; */
                     /* left: 224px; */
                     font-size: smaller;
              }
}

/* start code for apply css for rounded circle img */
.img-thumbnail{
     height: 94px;
     width: 94px;  
}
/* end code for apply css for rounded circle img */
